<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        <div>Filters</div>

        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <v-spacer />

        <v-btn icon small @click="hideFilter = !hideFilter">
          <v-icon small color="primary">mdi-chevron-down</v-icon>
        </v-btn>

        <v-btn icon small>
          <v-icon small color="primary" @click="resetFilter()">mdi-reload</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-show="!hideFilter" class="pb-4 pb-sm-8">
        <v-row>
          <v-col cols="12" sm="3">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="name"
              label="Name"
              v-model="query.name"
              id="name"
              placeholder="e.g. 2 Northdown"
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-text-field
              v-on:keypress="isNumber($event)"
              outlined
              dense
              hide-details
              clearable
              name="ticketsold"
              label="Ticket Sold"
              v-model="query.ticketSold"
              id="ticketsold"
              placeholder="e.g. 25"
            />
          </v-col>

          <v-col cols="12" sm="3">
            <v-select
              v-model="query.payoutStatus"
              :items="payoutStatuses"
              :item-text="'text'"
              :item-value="'value'"
              label="Payout Status"
              dense
              clearable
              @click:clear="clear('payoutStatus')"
              outlined
            ></v-select>
          </v-col>
           <v-col cols="12" sm="3">
            <v-select
              v-model="query.status"
              :items="statuses"
              :item-text="'text'"
              :item-value="'value'"
              label="Status"
              dense
              clearable
              @click:clear="clear('status')"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
         <!-- <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="query.promoters"
              :items="organisers"
              multiple
              clearable
              :filter="customFilter"
              :item-text="'name'"
              :item-value="'_id'"
              label="Promoter"
              dense
              outlined
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.name }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                  style="margin-left: 5px;"
                >
                   (+{{ query.promoters.length - 1 }} others)
                </span>
              </template>
              <template v-slot:item="data">
                <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
            </v-autocomplete>
          </v-col> -->
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="query.promoters"
              :items="organisers"
              :filter="customFilter"
              item-text="name"
              multiple
              clearable
              @click:clear="clear('promoters')"
              item-value="_id"
              label="Promoter"
              dense
              outlined
            >
              <template v-slot:selection="data">
              <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
              <template v-slot:item="{item, attrs, on}">
                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :ripple="false" :input-value="active"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-row no-gutters align="center">
                        {{ item.name }} 
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>  
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="query.genre"
              :items="genres"
              :item-text="'name'"
              :item-value="'name'"
              label="Event Category"
              dense
              clearable
              @click:clear="clear('genre')"
              outlined
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="4">
            <v-menu
              ref="mfilterEventDate"
              v-model="mfilterEventDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    @click:clear="clear('filterEventDate')"
                    dense
                    label="Event Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="parseEventDateFormat"
                  />
                </div>
              </template>
              <v-date-picker v-model="query.filterEventDate" range  @input="(query.filterEventDate.length === 2) ?  eventDatePicker=false :  eventDatePicker = true " @change="handeleEventDate">
                <v-btn 
                  text color="primary" @click="mfilterEventDate = false">Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                  text color="primary" @click="clearEventDate()">Clear
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <!--v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="promoter"
              label="Promoter"
              v-model="query.promoter"
              id="name"
              placeholder="e.g. 2 Northdown"
            />
          </v-col>
        </v-row-->
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-col cols="12" sm="8" md="4" xl="3">
            <v-tabs v-model="query.tabStatus">
              <v-tab href="#upcoming" @click="tabChange('upcoming')">Upcoming</v-tab>
              <v-tab href="#all" @click="tabChange('all')">All</v-tab>
            </v-tabs>
          </v-col>

          <v-spacer />

          <v-col cols="12" sm="3" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="6">
                <v-btn block outlined color="primary" @click="exportExcel()" :loading="loading.export">Export</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" @click="changeRoute()">Add New</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.title`]="{ item }">
          <span
            ><a @click="goToEventDetails(item)">{{ item.title }}</a></span
          >
        </template>
        <template v-slot:[`item.tickets`]="{ item }">
          <span>{{ item.tickets.totalPurchaseCount }}/{{ item.tickets.totalAvailableCount }}</span>
        </template>
        <template v-slot:[`item.progress`]="{ item }">
          <span
            ><v-progress-linear
              :value="
                item.tickets.totalPurchaseCount
                  ? Math.round((item.tickets.totalPurchaseCount / item.tickets.totalAvailableCount) * 100)
                  : 0
              "
              rounded
              height="8"
              color="primary"
            ></v-progress-linear
          ></span>
        </template>
        <template v-slot:[`item.payoutTotal`]="{ item }">
          <span>{{ item.payoutTotal | currency }}</span>
        </template>
        <template v-slot:[`item.payoutStatus`]="{ item }">
          <span>{{ item.payoutStatus | capitalize }}</span>
        </template>
        <template v-slot:[`item.startsAt`]="{ item }">
          <span>{{ item.startsAt | dateTime }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status | capitalize }}</span>
        </template>
        <!-- <template v-slot:[`item.startsAt`]="{ item }">
          <span>{{moment(item.startsAt).format('DD/MM/YYYY HH:mm')}}</span>
        </template> -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="editItem(item)">
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                title="Callover List"
                class="mr-1"
                @click="goToCalloverList(item)"
              >
                mdi-form-select
              </v-icon>
            </template>
            <span>Callover List</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="freeBookingPage(item)">
                mdi-ticket-confirmation-outline
              </v-icon>
            </template>
            <span>Free Ticket Booking</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                title="Duplicate"
                class="mr-1"
                @click="duplicateEvent(item)"
              >
                mdi-content-duplicate
              </v-icon>
            </template>
            <span>Duplicate</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="deleteItem(item)">
                mdi-delete-outline
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip v-if="item.isFeaturedEvent" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                title="Edit"
                class="mr-1"
                @click="unMarkFeaturedEvent(item)"
              >
                mdi-star
              </v-icon>
            </template>
            <span>Mark Event as Unfeatured</span>
          </v-tooltip>
          <v-tooltip v-if="!item.isFeaturedEvent && item.status !== 'cancelled'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                title="Edit"
                class="mr-1"
                @click="markFeaturedEvent(item)"
              >
                mdi-star-outline
              </v-icon>
            </template>
            <span>Mark Event as Featured</span>
          </v-tooltip>
          <v-tooltip top v-if="item.status === 'vetting'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="approveEvents(item)">
                mdi-checkbox-marked-circle-outline
              </v-icon>
            </template>
            <span>Approve Event</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="seeSurveyReport(item)">
                mdi-book-information-variant
              </v-icon>
            </template>
            <span>Survey Report</span>
          </v-tooltip>
          <v-tooltip
            top
            v-if="item.status === 'inactive' && item.payoutTotal > 0 && item.payoutStatus === 'Not Started'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon 
              color="primary" 
                v-bind="attrs"
                v-on="on"
                title="Edit"
                class="mr-1"
                @click="showPayoutPopup(item)"
              >
                mdi-cash
              </v-icon>
            </template>
            <span>Payout</span>
          </v-tooltip>
          <v-tooltip top v-if="item.status !== 'cancelled' && item.payoutStatus === 'Not Started'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="cancelEvents(item)">
                mdi-cancel
              </v-icon>
            </template>
            <span>Cancel Event</span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { PromoterEventService, EventService, PaymentService, PromoterService } from '@services';
import EventMixin from '@promoter/views/events/event.mixin'

export default {
  mixins: [EventMixin],
  data() {
    return {
      page: 1,
      hideFilter: false,
      eventDatePicker: false,
      organisers: [],
      query: {
        name: '',
        upcoming: true,
        ticketSold: '',
        payout: '',
        payoutStatus : 'all',
        status: '',
        date: '',
        genre: '',
        page: 1,
        tabStatus: 'upcoming',
        eventStarts: '',
        eventEnds: '',
        filterEventDate: [],
        promoters: [],
        //promoter: '',
      },
      payoutStatuses: [
        { text: 'All', value: 'all' },
        { text: 'Not Started', value: 'Not Started' },
        { text: 'Pending', value: 'pending' },
        { text: 'Paid', value: 'paid' },
      ],
      statuses: [
        { text: 'Draft', value: 'draft' },
        { text: 'Vetting', value: 'vetting' },
        { text: 'Active', value: 'active' },
        { text: 'Inactive', value: 'inactive' },
        { text: 'Paused', value: 'paused' },
        { text: 'Cancelled', value: 'cancelled' },
        // { text: 'Private', value: 'private' },
      ],
      genres: [],
      totalPage: '',
      overlay: false,
      headers: [
        { text: 'Actions', value: 'actions', sortable: false, width: '300px' },
        { text: 'Date & Time', value: 'startsAt', sortable: false, width: '200px' },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'title',
          width: '300px',
        },
        { text: 'Organiser', value: 'promoter', sortable: false, width: '200px' },
        { text: 'Tickets', value: 'tickets', sortable: false, width: '100px' },
        { text: 'Tickets Progress', value: 'progress', sortable: false, width: '200px' },
        { text: 'Status', value: 'status', sortable: false, width: '100px' },
        { text: 'Category', value: 'genre', sortable: false, width: '100px' },
        { text: 'Payout Total', value: 'payoutTotal', align: 'center', sortable: false, width: '150px' },
        { text: 'Payout Status', value: 'payoutStatus', sortable: false, width: '150px' },
      ],
      promoterId: '',
      desserts: [],
      loading: {
        export: false,
      },
      fromAdmin: true,
      isValidEventData: true,
      mfilterEventDate: false,
    };
  },
  computed: {
    parseEventDateFormat() {
      return this.$parseTransDateFormat(this.query.filterEventDate);
    }
  },
   mounted() {
    this.$root.$on('show-active-events', data => {
      this.query.status = 'active';
      this.getEvents();
    });
  },
  created() {
    this.promoterId = 'all';
    Object.assign(this.query, this.$getState());
    if (this.query.tabStatus === 'upcoming' || !this.query.tabStatus) {
      this.query.upcoming = true;
    } else {
      this.query.upcoming = '';
    }
    this.getEvents();
    this.getGenres();
    this.getOrganiser();
  },
  methods: {
    changeRoute() {
      this.$next({ name: 'admin.events.add' });
    },
    customFilter (item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    async getOrganiser() {
      const organiserData = await PromoterService.get({ limit: 'all' });
      this.organisers = organiserData.organisers;
    },
    async getEvents() {
      this.$setState(this.query);
      const data = await PromoterEventService.get(this.promoterId, this.query);
      if (data) {
        this.desserts = data.events;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    async getGenres() {
      const response = await PromoterEventService.getGenreSearch({ limit: 'all'});
      if (response) {
        this.genres = response.genres;
      }
    },
    tabChange(data) {
      this.query.page = 1;
      this.desserts = [];
      if (data === 'upcoming') {
        this.query.upcoming = true;
      } else {
        this.query.upcoming = '';
      }
      this.getEvents();
    },
    resetFilter() {
      this.query.page = 1;
      this.getEvents();
    },
    goToEventDetails(item) {
      this.$next({ name: 'admin.events.dashboard', params: { id: item.slug } });
    },
    editItem(item) {
      this.$next({ name: 'admin.events.edit', params: { id: item.slug } });
    },
    goToCalloverList(item) {
      this.$next({ name: 'admin.events.callover-list', params: { id: item._id } });
    },
    seeSurveyReport(item) {
      this.$next({ name: 'admin.events.survey-report', params: { id: item._id } });
    },
    async unMarkFeaturedEvent(item) {
      this.$confirm({
        title: `Do you want to mark the Event as UnFeatured?`,
        resolve: async () => {
            item.isFeaturedEvent = false;
            delete item.genre;
            delete item.tickets;
            delete item.organiser;
            const response = await PromoterEventService.update(item);
            if (response) {
              this.getEvents();
            }
        },
      });
    },
    async markFeaturedEvent(item) {
      this.$confirm({
        title: `Do you want to mark the Event as Featured?`,
        resolve: async () => {
          item.isFeaturedEvent = true;
          delete item.genre;
          delete item.tickets;
          delete item.organiser;
          const response = await PromoterEventService.update(item);
          if (response) {
            this.getEvents();
          }
        },
      });
    },
    async showPayoutPopup(item) {
      console.log('item', item)
      this.overlay = true;
      const response = await PromoterEventService.creaetPayout(item._id, { getValue: true });
      if (response) {
        if (response.isKYCReuired) {
          const stripeLinkResponse = await PaymentService.getStripeLink(response.accountId);
          this.overlay = false;
          EventService.$emit('show-kyc', 'Please complete KYC process to proceed further with Payouts.', 'show-kyc', stripeLinkResponse);
          return;
        }
        this.$confirm({
          promoterMessage: `${response.promoterMessage}`,
          ticketTextMessage: `${response.ticketTextMessage}`,
          confirmMessage: `${response.confirmMessage}`,
          resolve: async () => {
            const response = await PromoterEventService.creaetPayout(item._id);
            if (response) {
              this.getEvents();
              this.overlay = false;
            } else {
              this.overlay = false;
            }
          },
          reject: async () => {
            this.overlay = false;
          }
        });
      } else {
        this.overlay = false;
      }
    },
    async payout(item) {
      this.overlay = true;
      const response = await PromoterEventService.creaetPayout(item._id);
      if (response) {
        this.getEvents();
      }
      this.overlay = false;
    },
    freeBookingPage(item) {
      localStorage.setItem("freeTickets",item._id);
      // document.cookie = `freeTickets=${item._id}`;
       this.$next({
        name: 'visitor.event',
        params: { id: item.slug },
      });
    },
    async deleteItem(item) {
      this.$confirm({
        title: `Are you sure you want to delete this event?`,
        resolve: async () => {
          await PromoterEventService.delete(item._id);
          this.getEvents();
        },
      });
    },
    async approveEvents(item) {
      this.$confirm({
        title: `Are you sure you want to Approve the event?`,
        resolve: async () => {
          await PromoterEventService.sendApprovedMail({ eventId: item._id, organiserId: item.organiser });
          this.getEvents();
        },
      });
     
    },
    async cancelEvents(item) {
      this.$confirm({
        title: `Are you sure you want to cancel the event?`,
        resolve: async () => {
          await PromoterEventService.cancelEvent(item._id);
          this.getEvents();
        },
      });
    },
    clear(field) {
      if (field === 'promoters') {
        this.query[field] = [];
      } else {
        this.query[field] = '';
      }
      this.getEvents();
    },
    clearEventDate() {
      this.$refs.mfilterEventDate.save([]);
      this.query['eventStarts'] = '';
      this.query['eventEnds'] = '';
      this.query.filterEventDate = [];
      this.getEvents();
    },
    async exportExcel() {
      this.loading.export = true;
      this.query.isAdmin = true;
      const exportdata = await PromoterEventService.exportExcel(this.query);
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Event-${moment().format('DD/MM/YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      this.loading.export = false;
    },
    handeleEventDate(val) {
      this.isValidEventData = this.$validateDateRange(val);
    }
  },
  watch: {
    'query.name': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.name && this.query.name.length > 2) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.name) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.genre': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.genre && this.query.genre.length > 2) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.genre) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.ticketSold': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.ticketSold && this.query.ticketSold.length > 0) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.ticketSold) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.status': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.status && this.query.status.length > 2) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.status) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.payoutStatus': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.payoutStatus) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.payoutStatus) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.date': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.date && this.query.date.length > 2) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.date) {
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    },
    'query.page': function() {
      this.getEvents();
    },
    'query.filterEventDate': function() {
      if (this.query.filterEventDate.length > 1 && this.isValidEventData) {
        this.query.page = 1;
        this.query.eventStarts = this.query.filterEventDate[0];
        this.query.eventEnds = this.query.filterEventDate[1];
        this.getEvents();
      }
    },
    'query.promoters': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.promoters && this.query.promoters.length > 0) {
          this.query.page = 1;
          this.getEvents();
        }
        if (!this.query.promoters) {
          console.log("🚀 ~ file: list.vue ~ line 790 ~ this.debounce=setTimeout ~ this.query.promoters", this.query.promoters)
          this.query.page = 1;
          this.getEvents();
        }
      }, 600);
    }
  },
};
</script>
