<template>
  <v-dialog v-model="dialog" width="500px" @click:outside="cancel()">
    <v-form ref="form" no-validate>
      <v-card>
        <v-card-title class="justify-center">
          <b>{{ edit ? 'Edit' : 'Add' }} guest</b>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-autocomplete
              v-model="event"
              :items="events"
              item-text="title"
              clearable
              item-value="_id"
              @input="nameError = []"
              @change="onChangeName()"
              label="Events"
              :rules="[v => !!v || 'Events is required']"
              dense
              outlined
            ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="6" sm="3" class="hidden-xs-only">
              <v-btn block @click="cancel()"> Cancel </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3">
              <v-btn color="primary" block @click="save()"> Save </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { PromoterEventService, CustomWidgetService } from '@services';

export default {
  props: {
    value: { type: Object, required: true },
    index: { type: Number, required: false },
    guestList: { type: Object, required: false },
  },

  data() {
    return {
      dialog: true,
      events: [],
      event: ''
    };
  },

  mounted() {
    this.getEvents()
  },

  methods: {
    async getEvents() {
      const eventData = await PromoterEventService.getAll({ isUpcomig: true, status: 'active'});
      this.events = eventData;
    },
    async save() {
      if (this.$refs.form.validate()) {
        await CustomWidgetService.createGlobalEvent(this.event)
        this.dialog = false;
        this.$emit('close');
      }
    },
    cancel() {
      this.dialog = false;
      this.$emit('close');
    },
  },
};
</script>
