<template>
  <div>
    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-col cols="12" sm="8" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="12" sm="5">
                <v-menu
                  ref="mfilterTransDate"
                  v-model="mfilterTransDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div>
                      <v-text-field
                        outlined
                        hide-details
                        @click:clear="clear('filterTransDate')"
                        dense
                        label="Transaction Date"
                        append-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="parseTransDateFormat"
                      />
                    </div>
                  </template>
                  <v-date-picker v-model="query.filterTransDate" range  @change="handeleTransDate">
                    <v-btn 
                      text color="primary" @click="mfilterTransDate = false">Cancel
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn 
                      text color="primary" @click="clearDate()">Clear
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
               <v-col cols="12" sm="3">
                <v-btn block outlined color="primary" @click="getData()">Submit</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="2" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="12">
                <v-btn block color="primary" @click="exportExcel()">Export</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.grossSales`]="{ item }">
          <span>{{ item.grossSales | currency }}</span>
        </template>
        <template v-slot:[`item.totalBookingFee`]="{ item }">
          <span>{{ item.totalBookingFee | currency }}</span>
        </template>
        <template v-slot:[`item.totalServiceFee`]="{ item }">
          <span>{{ item.totalServiceFee | currency }}</span>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { ReportService } from '@services';
export default {
  data() {
    return {
      startdatePicker: false,
      enddatePicker: false,
      query: {
        startsAt: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        endsAt: moment().format('YYYY-MM-DD'),
        page: 1,
        filterTransDate: [moment().subtract(15, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },
      totalPage: '',
      headers: [
        {
          text: 'Event Name',
          align: 'start',
          sortable: false,
          value: 'title',
          width: '200px',
        },
        { text: 'Total ticket', value: 'totalTickets', sortable: false, width: '200px' },
        { text: 'Total Purchased Tickets', value: 'totalPurchaseCount', sortable: false, width: '200px' },
        { text: 'Gross Sales', value: 'grossSales', sortable: false, width: '200px' },
        { text: 'Total Booking Fees', value: 'totalBookingFee', sortable: false, width: '200px' },
        { text: 'Total Service Fees', value: 'totalServiceFee', sortable: false, width: '200px' },
      ],
      desserts: [],
      isValidTransDate: true,
      mfilterTransDate: false,
    };
  },
  created() {
    this.getData();
  },
  computed: {
    parseTransDateFormat() {
      return this.$parseTransDateFormat(this.query.filterTransDate);
    }
  },
  methods: {
    async getData() {
      if (this.query.filterTransDate.length > 1 && this.isValidTransDate) {
        this.query.startsAt = this.query.filterTransDate[0];
        this.query.endsAt = this.query.filterTransDate[1];
      }
      const data = await ReportService.get(this.query);
      if (data) {
        this.desserts = data.reports;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    resetFilter() {
      // this.query.startsAt = '';
      // this.query.endsAt = '';
      this.query.page = 1;
      this.getData();
    },
    async exportExcel() {
      const exportdata = await ReportService.exportExcel(this.query);
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Report-${moment().format('DD/MM/YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
    },
    handeleTransDate(val) {
      this.isValidTransDate = this.$validateDateRange(val);
    },
    clear(field) {
      this.getData();
    },
    clearDate() {
      this.$refs.mfilterTransDate.save([]);
      this.query['startsAt'] = '';
      this.query['endsAt'] = '';
      this.query.filterTransDate = [];
      this.getData();
    }
  },
  watch: {
    'query.page': function() {
      this.getData();
    },
  },
};
</script>
