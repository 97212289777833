<template>
  <div>
    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-col cols="12" sm="5">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="name"
              label="Name or Email"
              v-model="query.search"
              placeholder="e.g. Active"
            />
          </v-col>
          <v-col cols="12" sm="5">
            <v-autocomplete
              v-model="query.organiser"
              :items="organisers"
              multiple
              clearable
              :item-text="'name'"
              :item-value="'_id'"
              label="Promoter"
              dense
              outlined
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.name }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                  style="margin-left: 5px;"
                >
                   (+{{ query.organiser.length - 1 }} others)
                </span>
              </template>
               <template v-slot:item="data">
                <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
            </v-autocomplete>
          </v-col>          
          <v-col cols="12" sm="2">
            <v-btn block color="primary" @click="exportExcel()">Export</v-btn>
          </v-col>
          <v-col cols="12" sm="8">
            <v-autocomplete
              v-model="query.event"
              :items="events"
              clearable
              :item-text="'title'"
              :item-value="'_id'"
              label="Events"
              multiple
              dense
              outlined
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.title }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                  style="margin-left: 5px;"
                >
                   (+{{ query.event.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-select
              v-model="query.marketingType"
              :items="types"
              clearable
              :item-text="'title'"
              :item-value="'value'"
              label="Select Type"
              dense
              outlined
            >
            </v-select>
            <!-- <v-switch v-model="query.isTicketTextMarkeing" :label="`Ticket Text Marketing`"></v-switch> -->
          </v-col>
          <!-- <v-col cols="12" sm="2">
            <v-switch v-model="query.isPromoterMarketing" :label="`Promoter Marketing`"></v-switch>
          </v-col> -->
          <v-col cols="12" sm="3">
            <v-menu
              ref="mfilterTransDate"
              v-model="mfilterTransDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    @click:clear="clear('filterTransDate')"
                    dense
                    label="Transaction Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="parseTransDateFormat"
                  />
                </div>
              </template>
              <v-date-picker v-model="query.filterTransDate" range  @change="handeleTransDate">
                <v-btn 
                  text color="primary" @click="mfilterTransDate = false">Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                  text color="primary" @click="clearDate()">Clear
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ item.date | dateTime }}</span>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { ReportService, PromoterEventService, PromoterService } from '@services';
export default {
  data() {
    return {
      startdatePicker: false,
      enddatePicker: false,
      query: {
        starts: moment().subtract(15, 'days').format('YYYY-MM-DD'),
        ends: moment().format('YYYY-MM-DD'),
        page: 1,
        search: '',
        event: null,
        organiser: null,
        marketingType: 'all',
        filterTransDate: [moment().subtract(15, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },
      totalPage: '',
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '200px',
        },
        { text: 'Email', value: 'email', sortable: false, width: '200px' },
        { text: 'Event', value: 'eventName', sortable: false, width: '200px' },
        { text: 'Promoter', value: 'organiser', sortable: false, width: '200px' },
        { text: 'Ticket Text Marketing', value: 'canSendMail', sortable: false, width: '200px' },
        { text: 'Promoter Marketing', value: 'sendMarketingEmails', sortable: false, width: '200px' },
        { text: 'Date', value: 'date', sortable: false, width: '200px' },
      ],
      desserts: [],
      events: [],
      organisers: [],
      types:[{ title: 'All', value: 'all'}, { title: 'Ticket Text Marketing', value: 'canSendMail'}, { title: 'Promoter Marketing', value: 'promoterMarketing'}],
      isValidTransDate: true,
      mfilterTransDate: false,
    };
  },
  created() {
    this.getData();
    this.getEvents();
    this.getOrganiser();
  },
  computed: {
    parseTransDateFormat() {
      return this.$parseTransDateFormat(this.query.filterTransDate);
    }
  },
  methods: {
    async getEvents() {
      this.events = await PromoterEventService.getAll({});
    },
    async getOrganiser() {
      const organiserData = await PromoterService.get({ limit: 'all' });
      this.organisers = organiserData.organisers;
    },
    async getData() {
      const data = await ReportService.getMarketing(this.query);
      if (data) {
        this.desserts = data.reports;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    resetFilter() {
      this.query.page = 1;
      this.getData();
    },
    clear(field) {
      this.query[field] = '';
      this.getData();
    },
    customFilter (item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    clearDate() {
      this.$refs.mfilterTransDate.save([]);
      this.query['starts'] = '';
      this.query['ends'] = '';
      this.query.filterTransDate = [];
      this.getData();
    },
    async exportExcel() {
      const fileName = `Marketing-Report-${moment().format('DD/MM/YYYY')}.xlsx`;
      this.query.pageName = 'Marketing-Report';
      this.query.fileName = fileName;
      const exportdata = await ReportService.exportMarketing(this.query);
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    handeleTransDate(val) {
      this.isValidTransDate = this.$validateDateRange(val);
    }
  },
  watch: {
    'query.page': function() {
      this.getData();
    },
    'query.event': function() {
      this.query.page = 1;
      this.getData();
    },
    'query.organiser': function() {
      this.query.page = 1;
      this.getData();
    },
    'query.search': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.search && this.query.search.length > 2) {
          this.query.page = 1;
          this.getData();
        }
        if (!this.query.search) {
          this.query.page = 1;
          this.getData();
        }
      }, 600);
    },
    'query.isTicketTextMarkeing': function() {
      this.query.page = 1;
      this.getData();
    },
    'query.marketingType': function() {
      this.query.page = 1;
      this.getData();
    },
    'query.filterTransDate': function() {
      if (this.query.filterTransDate.length > 1 && this.isValidTransDate) {
        this.query.page = 1;
        this.query.starts = this.query.filterTransDate[0];
        this.query.ends = this.query.filterTransDate[1];
        this.getData();
      }
    }
  },
};
</script>
