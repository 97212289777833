<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab">
        <template v-if="item.tab === 'Report'">
            <Report/>
        </template>
        <template v-if="item.tab === 'Master Report'">
            <master-report/>
        </template>
        <template v-if="item.tab === 'Marketing Report'">
            <marketing-report/>
        </template>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Report from "./report.vue";
import MasterReport from "./master-report.vue";
import MarketingReport from "./marketing-report.vue";
export default {
  components: {
    Report,
    MasterReport,
    MarketingReport
  },
  data() {
    return {
      tab: null,
      items: [
        { tab: 'Report', Content: 'Report' },
        { tab: 'Master Report', Content: 'MasterReport' },
        { tab: 'Marketing Report', Content: 'Marketing Report' },
      ],
    };
  },
};
</script>
