<template>
  <div>
    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-spacer />
          <v-col cols="12" sm="4" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="6">
                <v-btn block outlined color="primary" @click="exportExcel()" :loading="loading.export">Export</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" @click="changeRoute()">Add new</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table item-key="_id" :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.description`]="{ item }">
          <span>{{ item.description || 'N/A' }}</span>
        </template>
        <template v-slot:[`item.organiser`]="{ item }">
          <span>{{ (item.organiser && item.organiser.name) ? item.organiser.name : 'N/A' }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="editItem(item)">
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Delete" class="mr-1" @click="deleteItem(item)">
                mdi-delete-outline
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Delete" class="mr-1" @click="eventExportExcel(item)">
                mdi-download-outline
              </v-icon>
            </template>
            <span>Export</span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { SurveyService } from '@services';
export default {
  props: {
    fromAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      actions: [
        { title: 'Delete', icon: 'mdi-delete-outline' },
        {
          title: 'Change status',
          icon: 'mdi-find-replace',
          items: [
            { title: 'Draft', key: 'draft' },
            { title: 'Active', key: 'active' },
            { title: 'Inactive', key: 'inactive' },
          ],
        },
      ],
      page: 1,
      totalPage: '',
      query: {
        page: 1,
        promoter: ''
      },
      headers: [
        { text: 'Actions', value: 'actions', sortable: false, width: '200px' },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '200px'
        },
        { text: 'Description', value: 'description', sortable: false, width: '600px' },
      ],
      desserts: [],
      loading: {
        export: false,
      },
    };
  },
  created() {
    Object.assign(this.query, this.$getState());
    this.getSurvey();
    if(this.fromAdmin) {
      this.headers.push({ text: 'Promoter', value: 'organiser', sortable: false, width: '200px' })
    }
  },
  methods: {
    async getSurvey() {
      this.query.promoter = this.$user.organiser;
      const data = await SurveyService.get(this.query);
      if (data) {
        this.desserts = data.survey;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    changeRoute() {
      if (this.fromAdmin) {
        this.$next({ name: 'admin.survey.add' });
      } else {
        this.$next({ name: 'promoter.survey.add' });
      }
    },
    editItem(item) {
      if (this.fromAdmin) {
        this.$next({ name: 'admin.survey.edit', params: { id: item._id } });
      } else {
        this.$next({ name: 'promoter.survey.edit', params: { id: item._id } });
      }
    },
    async deleteItem(item) {
      this.$confirm({
        title: `Are you sure you want to delete this survey?`,
        resolve: async () => {
          await SurveyService.delete(item._id);
          this.getSurvey();
        },
      });
    },
    async exportExcel() {
      this.loading.export = true;
      const exportData = await SurveyService.exportExcel(this.query);
      if (exportData) {
        const url = URL.createObjectURL(new Blob([exportData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Survey-${moment().format('DD/MM/YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      this.loading.export = false;
    },
    async eventExportExcel(item) {
      this.loading.export = true;
      const exportData = await SurveyService.eventExportExcel(this.query, item._id);
      if (exportData) {
        const url = URL.createObjectURL(new Blob([exportData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Survey-${moment().format('DD/MM/YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      this.loading.export = false;
    },
  },
  watch: {
    'query.page': function() {
      this.getSurvey();
    },
  },
};
</script>
