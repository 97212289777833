<template>
  <div>
    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-col cols="12" sm="4" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  clearable
                  name="name"
                  label="Search"
                  v-model="query.search"
                  placeholder="e.g. Active"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col cols="2">
            <v-btn block outlined color="primary" @click="exportExcel()">Export</v-btn>
          </v-col>
         
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ item.createdAt | dateTime }}</span>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span>{{ item.firstName }} {{ item.lastName }}</span>
        </template>
        <template v-slot:[`item.role`]="{ item }">
          <span>{{ item.role | capitalize }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Edit" class="mr-1" @click="editItem(item)">
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top v-if="item.role !== 'admin'">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="primary" v-bind="attrs" v-on="on" title="Delete" class="mr-1" @click="deleteItem(item)">
                mdi-delete-outline
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { SubscriptionService } from '@services';
export default {
  data() {
    return {
      query: {
        search: '',
        page: 1,
      },
      totalPage: '',
      headers: [
        {
          text: 'Event',
          align: 'start',
          sortable: false,
          value: 'event',
          width: '300px',
        },
        { text: 'Email', value: 'email', sortable: false, width: '200px' },
        { text: 'First Name', value: 'firstName', sortable: false, width: '200px' },
        { text: 'Last Name', value: 'lastName', sortable: false, width: '200px' },
      ],
      desserts: [],
    };
  },
  created() {
    Object.assign(this.query, this.$getState());
    this.getSubscriptions();
  },
  methods: {
    async getSubscriptions() {
      this.$setState(this.query);
      const data = await SubscriptionService.get(this.query);
      if (data) {
        this.desserts = data.subscripions;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    resetFilter() {
      this.query.search = '';
      this.query.page = 1;
      this.getSubscriptions();
    },
    async exportExcel() {
      const fileName = `Subscription-${moment().format('DD/MM/YYYY')}.xlsx`;
      this.query.pageName = 'Subscription';
      this.query.fileName = fileName;
      const exportdata = await SubscriptionService.exportExcel(this.query);
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
  },
  watch: {
    'query.search': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.search && this.query.search.length > 2) {
          this.query.page = 1;
          this.getSubscriptions();
        }
        if (!this.query.search) {
          this.query.page = 1;
          this.getSubscriptions();
        }
      }, 600);
    },
    'query.page': function() {
      this.getSubscriptions();
    },
  },
};
</script>
