<template>
  <div>
    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-col cols="12" sm="5">
            <v-autocomplete
              v-model="data.columns"
              :items="columns"
              multiple
              clearable
              :item-text="'title'"
              :item-value="'value'"
              label="Columns"
              dense
              outlined
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.name }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                  style="margin-left: 5px;"
                >
                   (+{{ data.columns.length }} Selected)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="5">
            <v-autocomplete
              v-model="data.query.organiser"
              :items="organisers"
              multiple
              clearable
              :filter="customFilter"
              :item-text="'name'"
              :item-value="'_id'"
              label="Promoter"
              dense
              outlined
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.name }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                  style="margin-left: 5px;"
                >
                   (+{{ data.query.organiser.length - 1 }} others)
                </span>
              </template>
              <template v-slot:item="data">
                <span>{{ data.item.name }} ( {{ data.item.type }} )</span>
              </template>
            </v-autocomplete>
          </v-col>          
          <v-col cols="12" sm="2">
            <v-btn block color="primary" @click="exportExcel()">Export</v-btn>
          </v-col>
          <v-col cols="12" sm="8">
            <v-autocomplete
              v-model="data.query.event"
              :items="events"
              clearable
              :item-text="'title'"
              :item-value="'_id'"
              label="Events"
              multiple
              dense
              outlined
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.title }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                  style="margin-left: 5px;"
                >
                   (+{{ data.query.event.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4">
            <v-autocomplete
              v-model="data.query.venue"
              :items="venues"
              clearable
              :item-text="'name'"
              :item-value="'_id'"
              label="Venue"
              multiple
              dense
              outlined
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.name }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                  style="margin-left: 5px;"
                >
                   (+{{ data.query.venue.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>          
          <v-col cols="12" sm="3">
            <v-menu
              ref="mfilterTransDate"
              v-model="mfilterTransDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    @click:clear="clear('filterTransDate')"
                    dense
                    label="Transaction Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="parseTransDateFormat"
                  />
                </div>
              </template>
              <v-date-picker v-model="query.filterTransDate" range  @change="handeleTransDate">
                <v-btn 
                  text color="primary" @click="mfilterTransDate = false">Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                  text color="primary" @click="clearDate()">Clear
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>

          <v-col cols="12" sm="3">
            <v-menu
              ref="mfilterEventDate"
              v-model="mfilterEventDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    @click:clear="clear('filterEventDate')"
                    dense
                    label="Event Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="parseEventDateFormat"
                  />
                </div>
              </template>
              <v-date-picker v-model="query.filterEventDate" range  @change="handeleEventDate">
                <v-btn 
                  text color="primary" @click="mfilterEventDate = false">Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                  text color="primary" @click="clearEventDate()">Clear
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="name"
              label="Event title or event subtitle or Artist Name"
              v-model="data.query.search"
              placeholder="e.g. Active"
            />
          </v-col>
          <v-spacer/>
           <v-col cols="12" sm="2">
              <v-btn block color="primary" @click="submit()">Submit</v-btn>
            </v-col>
          
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.eventDate`]="{ item }">
          <span>{{ item.eventDate | dateTime }}</span>
        </template>
        <template v-slot:[`item.grossSales`]="{ item }">
          <span>{{ item.grossSales | currency }}</span>
        </template>
        <template v-slot:[`item.faceValue`]="{ item }">
          <span>{{ item.faceValue | currency }}</span>
        </template>
        <template v-slot:[`item.bookingFees`]="{ item }">
          <span>{{ item.bookingFees | currency }}</span>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="data.query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { ReportService, PromoterEventService, PromoterService } from '@services';
export default {
  data() {
    return {
      startdatePicker: false,
      enddatePicker: false,
      query: {
        starts: moment().subtract(15, 'days').format('YYYY-MM-DD'),
        ends: moment().format('YYYY-MM-DD'),
        page: 1,
        search: '',
        event: null,
        organiser: null,
        marketingType: 'all',
        filterTransDate: [moment().subtract(15, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        filterEventDate: [],
        eventStarts: '',
        eventEnds: '',
      },
      totalPage: '',
      headers: [
        { text: 'Promoter Name', value: 'promoterName', sortable: false, width: '200px' },
        { text: 'Event Name', value: 'eventName', sortable: false, width: '200px' },
        { text: 'Event Date', value: 'eventDate', sortable: false, width: '200px' },
        { text: 'Venue Name', value: 'venue', sortable: false, width: '200px' },
        { text: '# of tickets sold', value: 'bookTickets', sortable: false, width: '200px' },
        { text: '# of Tickets Remaining', value: 'remainingTickets', sortable: false, width: '200px' },
        { text: 'Total Gross', value: 'grossSales', sortable: false, width: '200px' },
        { text: 'Face Value', value: 'faceValue', sortable: false, width: '200px' },
        { text: 'Booking Fee', value: 'bookingFees', sortable: false, width: '200px' }
      ],
      data: {
        columns: ['promoterName', 'eventName', 'eventDate', 'venue', 'bookTickets', 'remainingTickets', 'grossSales', 'faceValue', 'bookingFees'],
        query: {
          starts: moment().subtract(15, 'days').format('YYYY-MM-DD'),
          ends: moment().format('YYYY-MM-DD'),
          eventStarts: '',
          eventEnds: '',
          page: 1,
          search: '',
          event: null,
          organiser: null,
          venue: null,
        },
      },
      allColumns:[
        { text: 'Promoter Name', value: 'promoterName', sortable: false, width: '200px' },
        { text: 'Event Name', value: 'eventName', sortable: false, width: '200px' },
        { text: 'Event Date', value: 'eventDate', sortable: false, width: '200px' },
        { text: 'Venue Name', value: 'venue', sortable: false, width: '200px' },
        { text: '# of tickets sold', value: 'bookTickets', sortable: false, width: '200px' },
        { text: '# of Tickets Remaining', value: 'remainingTickets', sortable: false, width: '200px' },
        { text: 'Total Gross', value: 'grossSales', sortable: false, width: '200px' },
        { text: 'Face Value', value: 'faceValue', sortable: false, width: '200px' },
        { text: 'Booking Fee', value: 'bookingFees', sortable: false, width: '200px' },
        { text: 'Artist Name', value: 'artistName', sortable: false, width: '200px' },
        { text: 'Subtitle', value: 'subtitle', sortable: false, width: '200px' }
      ],
      columns:[{
        title: 'Promoter Name',
        value: 'promoterName'
      },
      {
        title: 'Event Name',
        value: 'eventName'
      },
      {
        title: 'Event Date',
        value: 'eventDate'
      },{
        title: 'Venue Name',
        value: 'venue'
      },{
        title: '# of tickets sold',
        value: 'bookTickets'
      },{
        title: '# of Tickets Remaining',
        value: 'remainingTickets'
      },{
        title: 'Total Gross',
        value: 'grossSales'
      },{
        title: 'Face Value',
        value: 'faceValue'
      },{
        title: 'Booking Fee',
        value: 'bookingFees'
      },{
        title: 'Artist Name',
        value: 'artistName'
      },{
        title: 'Subtitle',
        value: 'subtitle'
      }],
      desserts: [],
      events: [],
      organisers: [],
      venues: [],
      types:[{ title: 'All', value: 'all'}, { title: 'Ticket Text Marketing', value: 'canSendMail'}, { title: 'Promoter Marketing', value: 'promoterMarketing'}],
      isValidTransDate: true,
      isValidEventData: true,
      mfilterTransDate: false,
      mfilterEventDate: false,
    };
  },
  created() {
    this.getData();
    this.getEvents();
    this.getOrganiser();
    this.getVenues();
  },
  computed: {
    parseTransDateFormat() {
      return this.$parseTransDateFormat(this.query.filterTransDate);
    },
    parseEventDateFormat() {
      return this.$parseTransDateFormat(this.query.filterEventDate);
    }
  },
  methods: {
    async getEvents() {
      this.events = await PromoterEventService.getAll({ organisers: this.data.query.organiser});
    },
    customFilter (item, queryText) {
      const textOne = `${item.name.toLowerCase()} - ${item.type.toLowerCase()}`
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },
    async getOrganiser() {
      const organiserData = await PromoterService.get({ limit: 'all' });
      this.organisers = organiserData.organisers;
    },
    async getData() {
      const data = await ReportService.getMasterReport(this.data);
      if (data) {
        this.desserts = data.reports;
        console.log("🚀 ~ file: master-report.vue ~ line 297 ~ getData ~ this.desserts", this.desserts)
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    async getVenues() {
      const response = await PromoterEventService.getVenuesSearch({ limit: 'all', status: 'active' });

      if (response) {
        this.venues = response;
      }
    },
    resetFilter() {
      this.query.page = 1;
      this.getData();
    },
    clear(field) {
      this.query[field] = '';
      this.getData();
    },
    clearDate() {
      this.$refs.mfilterTransDate.save([]);
      this.data.query['starts'] = '';
      this.data.query['ends'] = '';
      this.query.filterTransDate = [];
      this.getData();
    },
    clearEventDate() {
      this.$refs.mfilterEventDate.save([]);
      this.data.query['eventStarts'] = '';
      this.data.query['eventEnds'] = '';
      this.query.filterEventDate = [];
      this.getData();
    },
    async exportExcel() {
      const exportdata = await ReportService.exportMasterReport(this.data);
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Report-${moment().format('DD/MM/YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
    },
    async submit() {
      this.headers = [];
      this.data.query.page = 1;
      for (let index = 0; index < this.allColumns.length; index++) {
        const element = this.allColumns[index];
        if (this.data.columns.indexOf(element.value) > -1) {
          this.headers.push(element);
        }
      }
      this.getData();
    },
    handeleTransDate(val) {
      this.isValidTransDate = this.$validateDateRange(val);
    },
    handeleEventDate(val) {
      this.isValidEventData = this.$validateDateRange(val);
    },
  },
  watch: {
    'data.query.page': function() {
      this.getData();
    },
    'data.query.event': function() {
      this.data.query.page = 1;
      this.getData();
    },
    'data.query.organiser': function() {
      this.data.query.page = 1;
      this.getEvents();
      this.getData();
    },
    'data.query.search': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.data.query.search && this.data.query.search.length > 2) {
          this.data.query.page = 1;
          this.getData();
        }
        if (!this.data.query.search) {
          this.data.query.page = 1;
          this.getData();
        }
      }, 600);
    },
    'data.query.venue': function() {
      this.data.query.page = 1;
      this.getData();
    },
    'query.filterTransDate': function() {
      if (this.query.filterTransDate.length > 1 && this.isValidTransDate) {
        this.data.query.page = 1;
        this.data.query.starts = this.query.filterTransDate[0];
        this.data.query.ends = this.query.filterTransDate[1];
        this.getData();
      }
    },
    'query.filterEventDate': function() {
      if (this.query.filterEventDate.length > 1 && this.isValidEventData) {
        this.data.query.page = 1;
        this.data.query.eventStarts = this.query.filterEventDate[0];
        this.data.query.eventEnds = this.query.filterEventDate[1];
        this.getData();
      }
    }
  },
};
</script>
