<template>
  <div>
    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="name"
              label="Name or Email"
              v-model="query.search"
              placeholder="e.g. Active"
            />
          </v-col>
                  
          
          <v-col cols="12" sm="3">
            <v-menu
              ref="mfilterTransDate"
              v-model="mfilterTransDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    @click:clear="clear('filterTransDate')"
                    dense
                    label="Transaction Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="parseTransDateFormat"
                  />
                </div>
              </template>
              <v-date-picker v-model="query.filterTransDate" range  @input="(query.filterTransDate.length === 2) ? transactionDate = false : transactionDate = true" @change="handeleTransDate">
                <v-btn 
                  text color="primary" @click="mfilterTransDate = false">Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                  text color="primary" @click="clearDate()">Clear
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="3">
            <v-menu
              ref="mfilterEventDate"
              v-model="mfilterEventDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <div>
                  <v-text-field
                    outlined
                    hide-details
                    @click:clear="clear('filterEventDate')"
                    dense
                    label="Event Date"
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :value="parseEventDateFormat"
                  />
                </div>
              </template>
              <v-date-picker v-model="query.filterEventDate" range  @input="(query.filterEventDate.length === 2) ? eventDatePicker = false : eventDatePicker = true" @change="handeleEventDate">
                <v-btn 
                  text color="primary" @click="mfilterEventDate = false">Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn 
                  text color="primary" @click="clearEventDate()">Clear
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          

          <v-col cols="12" sm="2">
            <v-btn block color="primary" @click="exportExcel()">Export</v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="query.eventIds"
              :items="events"
              clearable
              :item-text="'title'"
              :item-value="'_id'"
              label="Events"
              multiple
              dense
              outlined
            >
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">{{ item.title }}</span>
                <span
                  v-if="index === 1"
                  class="grey--text text-caption"
                  style="margin-left: 5px;"
                >
                   (+{{ query.eventIds.length - 1 }} others)
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              outlined
              dense
              hide-details
              clearable
              name="utmcode"
              label="UTM Code"
              v-model="query.utmcode"
              placeholder="e.g. B75NIWBM"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-select
              v-model="query.ticketStatus"
              :items="statuses"
              :item-text="'text'"
              :item-value="'value'"
              label="Status"
              dense
              clearable
              @click:clear="clear('status')"
              outlined
            ></v-select>
          </v-col>
          
          
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.amount`]="{ item }">
          <span>{{ item.amount | currency }}</span>
        </template>
        <template v-slot:[`item.seat`]="{ item }">
          <span>{{ item.seat || 'N/A' }}</span>
        </template>
        <template v-slot:[`item.ticketStatus`]="{ item }">
          <span>{{ item.ticketStatus || 'N/A' }}</span>
        </template>
        <template v-slot:[`item.actualTicketPrice`]="{ item }">
          <span>{{ item.actualTicketPrice | currency }}</span>
        </template>
        <template v-slot:[`item.bookingFee`]="{ item }">
          <span>{{ item.bookingFee | currency }}</span>
        </template>
        <template v-slot:[`item.discount`]="{ item }">
          <span>{{ item.discount | currency }}</span>
        </template>
        <template v-slot:[`item.refundAmount`]="{ item }">
          <span>{{ item.refundAmount | currency }}</span>
        </template>
         <template v-slot:[`item.eventStartAt`]="{ item }">
          <span>{{ item.eventStartAt | dateTime }}</span>
        </template>
        <template v-slot:[`item.bookingDate`]="{ item }">
          <span>{{ item.createdAt | dateTime }}</span>
        </template>
         <template v-slot:[`item.seatCount`]="{ item }">
          <span>{{ ((item.seatCount || 0) - (item.refundCount || 0)) >= 0 ? ((item.seatCount || 0) - (item.refundCount || 0)) : 0 }}</span>
        </template>
        <template v-slot:[`item.refundCount`]="{ item }">
          <span>{{ item.refundCount || 0 }}</span>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { PromoterEventService } from '@services';
export default {
  data() {
    return {
      startdatePicker: false,
      eventDatePicker: false,
      transactionDate: false,
      query: {
        starts: moment().subtract(15, 'days').format('YYYY-MM-DD'),
        ends: moment().format('YYYY-MM-DD'),
        page: 1,
        search: '',
        eventIds: null,
        sort: 'date',
        ticketStatus: 'BOOKED',
        isOrderHistory: true,
        eventStarts: '',
        eventEnds: '',
        filterEventDate: [],
        filterTransDate: [moment().subtract(15, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      },
      statuses: [
        { text: 'BOOKED', value: 'BOOKED' },
        { text: 'REFUNDED', value: 'REFUND' },
      ],
      totalPage: '',
      headers: [
        {
          text: 'Name',
          sortable: false,
          value: 'name',
          width: '200px',
        },
        { text: 'Event Name', value: 'eventTitle', sortable: false, width: '250px' },
        { text: 'Event Date', value: 'eventStartAt', sortable: false, width: '250px' },
        { text: 'Email', value: 'email', sortable: false, width: '250px' },
        { text: 'Ticket Category', value: 'seat', sortable: false, width: '150px' },
        { text: '#Seats', value: 'seatCount', sortable: false, width: '200px' },
        { text: '#Refunded Seats', value: 'refundCount', sortable: false, width: '200px' },
        { text: 'Ticket Price', value: 'actualTicketPrice', sortable: false, width: '150px' },
        { text: 'Booking Fees', value: 'bookingFee', sortable: false, width: '150px' },
        { text: 'Discount', value: 'discount', sortable: false, width: '150px' },
        { text: 'Amount Paid', value: 'amount', sortable: false, width: '200px' },
        { text: 'Amount Refunded', value: 'refundAmount', sortable: false, width: '200px' },
        { text: 'Ticket Status', value: 'ticketStatus', sortable: false, width: '150px' },
        { text: 'Free Ticket', value: 'isFreeTicket', sortable: false, width: '150px' },
        { text: 'Booking Date', value: 'bookingDate', sortable: false, width: '200px' },
        { text: 'UTM Code', value: 'utmCode', sortable: false, width: '200px' },
      ],
      desserts: [],
      events: [],
      organisers: [],
      types:[{ title: 'All', value: 'all'}, { title: 'Ticket Text Marketing', value: 'canSendMail'}, { title: 'Promoter Marketing', value: 'promoterMarketing'}],
      isValidTransDate: true,
      isValidEventData: true,
      mfilterTransDate: false,
      mfilterEventDate: false,
    };
  },
  created() {
    this.getData();
    this.getEvents();
  },
  computed: {
    parseTransDateFormat() {
      return this.$parseTransDateFormat(this.query.filterTransDate);
    },
    parseEventDateFormat() {
      return this.$parseTransDateFormat(this.query.filterEventDate);
    }
  },
  methods: {
    async getEvents() {
      this.events = await PromoterEventService.getAll({});
    },
    async getData() {
      // this.$setState(this.query);
      const data = await PromoterEventService.getTickets(this.query);
      if (data) {
        this.desserts = data.tickets;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    resetFilter() {
      this.query.page = 1;
      this.getData();
    },
    clear(field) {
      this.query[field] = '';
      this.getData();
    },
    clearDate() {
      this.$refs.mfilterTransDate.save([]);
      this.query['starts'] = '';
      this.query['ends'] = '';
      this.query.filterTransDate = [];
      this.getData();
    },
    clearEventDate() {
      this.$refs.mfilterEventDate.save([]);
      this.query['eventStarts'] = '';
      this.query['eventEnds'] = '';
      this.query.filterEventDate = [];
      this.getData();
    },
    async exportExcel() {
      const fileName = `Customer-Order-history-${moment().format('DD/MM/YYYY')}.xlsx`;
      this.query.pageName = 'Customer Order history';
      this.query.fileName = fileName;
      const exportdata = await PromoterEventService.PromoterEventService(this.query);
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      }
    },
    handeleTransDate(val) {
      this.isValidTransDate = this.$validateDateRange(val);
    },
    handeleEventDate(val) {
      this.isValidEventData = this.$validateDateRange(val);
    },
  },
  watch: {
    'query.page': function() {
      this.getData();
    },
    'query.eventIds': function() {
      this.query.page = 1;
      this.getData();
    },
    'query.organiser': function() {
      this.query.page = 1;
      this.getData();
    },
    'query.search': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.search && this.query.search.length > 2) {
          this.query.page = 1;
          this.getData();
        }
        if (!this.query.search) {
          this.query.page = 1;
          this.getData();
        }
      }, 600);
    },
    'query.ticketStatus': function() {
        this.query.page = 1;
        this.getData();
    },
    'query.utmcode': function() {
        this.query.page = 1;
        this.getData();
    },
    'query.filterTransDate': function() {
      if (this.query.filterTransDate.length > 1 && this.isValidTransDate) {
        this.query.page = 1;
        this.query.starts = this.query.filterTransDate[0];
        this.query.ends = this.query.filterTransDate[1];
        this.getData();
      }
    },
    'query.filterEventDate': function() {
      if (this.query.filterEventDate.length > 1 && this.isValidEventData) {
        this.query.page = 1;
        this.query.eventStarts = this.query.filterEventDate[0];
        this.query.eventEnds = this.query.filterEventDate[1];
        this.getData();
      }
    }
  },
};
</script>
