import _ from 'lodash';
import { PromoterEventService } from '@services';

export default {
  methods: {
    randomString(length, chars) {
      var result = '';
      for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
      return result;
    },
    async duplicateEvent(item) {
      this.$confirm({
        title: `Are you sure you want to duplicate this event?`,
        resolve: async () => {
          const event = await PromoterEventService.getById(item.slug);
          if (event) {
            const string = Math.random()
              .toString(36)
              .substring(5);
              
            event.title = event.title + ' - Copy';
            event.slug = this.randomString(10, '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');;
            event.status = 'draft';

            event.performers = (event.performers || [])[0]?._id;
            event.venue = event.venue?._id;
            event.genre = event.genre?._id;
            event.seatingPlan = event.seatingPlan?._id;
            event.organiser = event.organiser?._id || event.organiser || this.$user.organiser;

            event.possibleDeliveryMethods = event.possibleDeliveryMethods.filter(x => x.method);
            event.isHaveAddson = false;
            event.isHaveSurvey = false;
            if (event.seatsIOEventId) {
              event.seatsIOEventId = null;
              event.seatingPlan = null;
              event.ticketCategories = [];
            }

            const response = await PromoterEventService.save(
              _.omit(event, [
                '_id',
                'ticketCategories',
                'promocodes',
                'startsAt',
                'endsAt',
                'doors',
                'image',
                'promoterPayout',
                'ticketTextPayout',
                'promoterTransfer',
                'ticketTextTransfer',
                'guests',
                'addson'
              ])
            );

            if (response) {
              event._id = response._id;

              const tickets = [];
              event.ticketCategories?.forEach(ticket => {
                ticket.event = event._id;
                ticket.seatingPlan = event.seatingPlan?._id;
                ticket.category = ticket.category?._id;
                ticket.purchaseCount = 0;
                ticket.bucketTickets = 0;
                tickets.push(_.cloneDeep( _.omit(ticket,['_id'])));
              });

              if (tickets.length) {
                const response = await PromoterEventService.saveTickets(event._id, tickets);
                if (response) {
                  // const eventData = await PromoterEventService.getById(event.slug);
                  // if (eventData) {
                  //   even
                  // }
                }
              }

              this.$next({
                name: this.fromAdmin ? 'admin.events.edit' : 'promoter.events.edit',
                params: { id: event.slug },
                query: { isDuplicate: 'true' },
              });
            }
          }
        },
      });
    },
  },
};
