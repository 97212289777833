<template>
  <div>
    <v-card>
      <v-card-title class="d-flex">
        <v-row>
          <v-col cols="12" sm="4" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  outlined
                  dense
                  hide-details
                  clearable
                  name="name"
                  label="Search"
                  v-model="query.search"
                  placeholder="e.g. Active"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col cols="12" sm="4" xl="3" class="text-left text-sm-right">
            <v-row>
              <v-col cols="6">
                <v-btn block outlined color="primary" @click="exportExcel()">Export</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn block color="primary" @click="changeRoute()">Add new</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-data-table :headers="headers" :items="desserts" hide-default-footer>
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ item.startsAt | dateTime }} - {{ item.expiresAt | dateTime }}</span>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <span>{{ item.status | capitalize }}</span>
        </template>
        <template v-slot:[`item.remainingRedemptions`]="{ item }">
          <span>{{ item.numberOfRedemptions }}/{{ item.totalRedemptions }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon color="primary" class="mr-1" @click="editItem(item)">
            mdi-pencil-outline
          </v-icon>
          <v-icon color="primary" class="mr-1" @click="deleteItem(item)">
            mdi-delete-outline
          </v-icon>
        </template>
        <template v-slot:footer>
          <v-pagination
            v-if="totalPage"
            class="text-right text-body-2 py-2 "
            circle
            :total-visible="5"
            color="primary"
            v-model="query.page"
            :length="totalPage"
          ></v-pagination>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment';
import { EventTypeService } from '@services';
export default {
  data() {
    return {
      query: {
        search: '',
        page: 1,
      },
      totalPage: '',
      headers: [
        { text: 'Actions', value: 'actions', align: 'center', sortable: false, width: '30%' },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '70%',
        },
      ],
      desserts: [],
    };
  },
  created() {
    Object.assign(this.query, this.$getState());
    this.getEventTypes();
  },
  methods: {
    async getEventTypes() {
      this.$setState(this.query);
      const data = await EventTypeService.get(this.query);
      if (data) {
        this.desserts = data.eventTypes;
        this.totalPage = Number(parseInt((data.totalCount + 10 - 1) / 10));
      }
    },
    resetFilter() {
      this.query.search = '';
      this.query.page = 1;
      this.getEventTypes();
    },
    changeRoute() {
      this.$next({ name: 'admin.event-type.edit' });
    },
    editItem(item) {
      this.$next({ name: 'admin.event-type.edit', params: { id: item._id } });
    },
    async deleteItem(item) {
      this.$confirm({
        title: `Are you sure you want to delete this EventType?`,
        resolve: async () => {
          await EventTypeService.delete(item._id);
          this.getEventTypes();
        },
      });
    },
    async exportExcel() {
      const exportdata = await EventTypeService.exportExcel(this.query);
      if (exportdata) {
        const url = URL.createObjectURL(new Blob([exportdata]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `EventType-${moment().format('DD/MM/YYYY')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
    },
  },
  watch: {
    'query.search': function() {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        if (this.query.search && this.query.search.length > 2) {
          this.query.page = 1;
          this.getEventTypes();
        }
        if (!this.query.search) {
          this.query.page = 1;
          this.getEventTypes();
        }
      }, 600);
    },
  },
  'query.page': function() {
    this.getEventTypes();
  },
};
</script>
